import { useLang } from "_metronic/i18n/Metronici18n";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import { DatePicker, DatePickerProps, Select } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { ResetComponent } from "app/_components/table/header/ResetButton";
import { RowCountComponent } from "app/_components/table/header/RowsCountComponent";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import DateRangeCustom from "./components/DateRangeCustom";
import SearchCustom from "./components/SearchCustom";
import SelectMultiOptions from "./components/SelectMultiOptions";

const UsersListHeader = ({ optionsHeader }: any) => {
  const intl = useIntl();
  const { updateState, resetState, state } = useQueryRequest();

  const lang = useLang();
  const defaultDate = dayjs().add(-1, "w");
  const defaultMonth = dayjs().add(-1, "month");

  const weekFormat = "MM/DD";
  const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .format(weekFormat)}`;

  const renderOptionsHeader = () => {
    return optionsHeader.map((item: any, index: number) => {
      switch (item.id) {
        case "LIMIT":
          return (
            <div key={index} className="card-toolbar align-items-end">
              <RowCountComponent
                updateState={updateState}
                state={state}
                useQueryRequest={useQueryRequest}
              />
            </div>
          );
        case "SEARCH":
          return (
            <SearchCustom
              updateState={updateState}
              state={state}
              key={index}
              placeholder={intl.formatMessage({ id: item.placeholder })}
              searchType={item?.searchType || "search"}
            />
          );

        case "DATE-RANGE":
          return (
            <DateRangeCustom
              state={state}
              updateState={updateState}
              key={index}
              title={intl.formatMessage({ id: item.label })}
            />
          );

        case "DATE-RANGE-RANKING-WEEKLY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>
              <DatePicker
                key={index}
                format={customWeekStartEndFormat}
                picker="week"
                onChange={(value) => {
                  const startWeek = dayjs(value)
                    .startOf("week")
                    .format("YYYY-MM-DD");
                  const endWeek = dayjs(value)
                    .endOf("week")
                    .format("YYYY-MM-DD");

                  updateState({
                    start_date: startWeek,
                    end_date: endWeek,
                  });
                }}
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date).startOf("week")
                    : defaultDate
                }
                defaultValue={defaultDate}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );
        case "DATE-RANGE-RANKING-DAILY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>

              {/* begin::Search */}
              <DatePicker
                onChange={(data, dateString) => {
                  updateState({
                    start_date: dateString,
                    end_date: dateString,
                  });
                }}
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date)
                    : dayjs().add(-1, "day")
                }
                defaultValue={dayjs().add(-1, "day")}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );

        case "DATE-RANGE-RANKING-MONTHLY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>
              <DatePicker
                picker="month"
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date).startOf("month")
                    : defaultMonth
                }
                onChange={(value) => {
                  const startWeek = dayjs(value)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                  const endWeek = dayjs(value)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                  updateState({
                    start_date: startWeek,
                    end_date: endWeek,
                  });
                }}
                defaultValue={defaultMonth}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );
        case "MULTI-OPTIONS":
          return (
            <SelectMultiOptions
              state={state}
              updateState={updateState}
              key={index}
              dataOptions={item.dataOptions}
              title={item.title}
              type={item.type}
            />
          );
        case "SELECT-OPTION":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: item.label })}:{" "}
              </span>
              <div className="d-flex align-items-center position-relative">
                <Select
                  onChange={(value: any) => {
                    updateState({
                      [`${item.type_filter_key}`]: value.toString(),
                      page: 1,
                    });
                  }}
                  options={item.dataOptions.map((i: any) => {
                    return {
                      ...i,
                      label: intl.formatMessage({ id: i.label }),
                    };
                  })}
                  //defaultValue={item.dataOptions[0].value}
                  value={
                    state[`${item.type_filter_key}`] ||
                    item.dataOptions[0].value
                  }
                  style={{ width: 120 }}
                  // options={[
                  //   { value: "jack", label: "Jack" },
                  //   { value: "lucy", label: "Lucy" },
                  //   { value: "Yiminghe", label: "yiminghe" },
                  //   { value: "disabled", label: "Disabled", disabled: true },
                  // ]}
                />
                {/* <Select
                  onChange={(value: any) => {
                    updateState({
                      [`${item.type_filter_key}`]: value.toString(),
                      page: 1,
                    });
                  }}
                  // value={
                  //   state[`${item.type_filter_key}`] === "true"
                  //     ? intl.formatMessage({
                  //         id: item.dataOptions[1].label,
                  //       })
                  //     : intl.formatMessage({
                  //         id: item.dataOptions[0].label,
                  //       })
                  // }
                  value={intl.formatMessage({
                    id: item.dataOptions[1].label,
                  })}
                  defaultValue={intl.formatMessage({
                    id: item.dataOptions[0].label,
                  })}
                  options={item.dataOptions.map((i: any) => {
                    return {
                      ...i,
                      label: intl.formatMessage({ id: i.label }),
                    };
                  })}
                  // style={{ height: 40, width: 100 }}
                /> */}
              </div>
            </div>
          );
        case "RESET":
          return (
            <div key={index} className="card-toolbar align-items-end">
              <ResetComponent resetState={resetState} />
            </div>
          );
      }
    });
  };

  return (
    <FilterHeader>
      <div className="w-100 px-0 py-3 rounded d-flex align-items-center flex-wrap me-5 filter-header gap-4">
        {renderOptionsHeader()}
      </div>
    </FilterHeader>
  );
};

export { UsersListHeader };
