import { callApiWithTryCatch } from "app/utils/apiUntils";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/users`;

export const getPanigationUsers = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

const edit_user = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/update`, body);
};

const delete_admin = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}/delete`);
};

const block_user = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/block`, body);
};

const unBlock_user = (id: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/unblock`);
};

const edit_user_type = (id: string, type: string) => {
  return axios.put(`${GET_USERS_URL}/${id}/type/${type}`);
};

export { delete_admin, edit_user, block_user, unBlock_user, edit_user_type };
