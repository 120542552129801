import clsx from "clsx";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { HeaderProps } from "react-table";
import { initialQueryState } from "_metronic/helpers";
import { useIntl } from "react-intl";
import { KTIcon } from "_metronic/helpers";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<any>>;
  useQueryRequest?: any;
  isNoSort?: boolean;
  isCenter?: boolean;
  isForceDesc?: boolean;
};
const UserCustomHeader: FC<Props> = ({
  className,
  title,
  tableProps,
  useQueryRequest,
  isNoSort = true,
  isCenter = false,
  isForceDesc = false,
}) => {
  const intl = useIntl();

  const id = tableProps.column.id;
  const { state, updateState } = useQueryRequest();

  const [countSort, setCountSort] = useState(0);

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id;
  }, [state, id]);
  const order: "asc" | "desc" | undefined = useMemo(() => state.order, [state]);

  const isActionColumn = id.includes("action");

  const sortColumn = () => {
    // avoid sorting for these columns
    if (isActionColumn || id === "selection" || isNoSort) {
      return;
    }
    setCountSort(countSort + 1);

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({ sort: id, order: "asc", ...initialQueryState });
      return;
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === "asc") {
        // enable sort desc
        updateState({ sort: id, order: "desc", ...initialQueryState });
        return;
      }

      // disable sort
      updateState({ sort: undefined, order: undefined, ...initialQueryState });
    }
  };

  if (state.sort !== id && state.sort) isForceDesc = false;

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
        //isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{
        cursor: "pointer",
        textAlign: isActionColumn || isCenter ? "center" : "left",
      }}
      onClick={sortColumn}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={isNoSort ? { flex: 1 } : {}}>
          {intl.formatMessage({ id: title })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            visibility: isNoSort ? "hidden" : "visible",
          }}
        >
          <ArrowDropUpIcon
            fontSize="small"
            style={{
              position: "relative",
              top: 5,
              fill:
                order == "asc" && isSelectedForSorting ? "#858383" : "#dedede",
            }}
          />
          <ArrowDropDownIcon
            fontSize="small"
            style={{
              position: "relative",
              top: -5,
              fill:
                (order == "desc" && isSelectedForSorting) ||
                (!order && isForceDesc && countSort === 0)
                  ? "#858383"
                  : "#dedede",
            }}
          />
        </div>
      </div>
    </th>
  );
};

export { UserCustomHeader };
