// @ts-nocheck
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import moment from "moment";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-100px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <PlatformCell className="min-w-100px" user={row.user} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="OS"
        useQueryRequest={useQueryRequest}
        className="min-w-100px"
      />
    ),
    id: "device_os",
    accessor: "device_os",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <CellOS row={row.user} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "email",
    accessor: "email",
    Cell: ({ ...props }) => {
      const row = props.row.original;

      return <UserInfoCell user={row.user} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    isCenterRow: true,
    id: "action",
    accessor: "action",
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <ActionCell value={row.action} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="MESSAGE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    isCenterRow: true,
    id: "message",
    accessor: "message",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LOG-TIME"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    Cell: ({ ...props }) => {
      const timestamp = props?.row?.original.timestamp;
      const date = moment.unix(timestamp / 1000).format("YYYY-MM-DD HH:mm:ss");
      return <DateTimeCell date={date} />;
    },
  },
];

const ActionCell = ({ value }: any) => {
  const intl = useIntl();
  return <div>{intl.formatMessage({ id: value.toUpperCase() })}</div>;
};

const CellOS = ({ row }) => {
  const intl = useIntl();
  const data = row.device_os && row.device_os.split(",");
  const convertData =
    data &&
    data.length > 1 &&
    data.map((item) => intl.formatMessage({ id: `${item?.toUpperCase()}` }));

  return (
    <div>
      {row.device_os
        ? convertData.length > 0
          ? convertData.toString()
          : intl.formatMessage({ id: `${row.device_os?.toUpperCase()}` })
        : ""}
    </div>
  );
};
const PlatformCell = ({ user, className = "" }) => {
  let imageSrc;
  switch (user?.platform) {
    case "amazon":
      imageSrc = toAbsoluteUrl("/media/social_media/amazon.svg");
      break;

    case "apple":
      imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
      break;

    case "facebook":
      imageSrc = toAbsoluteUrl("/media/social_media/facebook.svg");
      break;

    case "google":
      imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
      break;

    case "kakao":
      imageSrc = toAbsoluteUrl("/media/social_media/kakao.svg");
      break;

    case "naver":
      imageSrc = toAbsoluteUrl("/media/social_media/naver.svg");
      break;

    default:
      imageSrc = toAbsoluteUrl("/media/social_media/wishnote.svg");
  }

  return (
    <div className={`${className} d-flex align-items-center`}>
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <div className={clsx("symbol-label fs-3")}>
          <img src={imageSrc} className="w-50" />
        </div>
      </div>
    </div>
  );
};

export { usersColumns };
