/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { useIntl } from "react-intl";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "LOGIN.VALIDATE.EMAIL.WRONGFORMAT" }))
      .min(3, intl.formatMessage({ id: "LOGIN.VALIDATE.EMAIL.MIN" }))
      .max(50, "Maximum 50 symbols")
      .required(intl.formatMessage({ id: "LOGIN.VALIDATE.EMAIL.REQUIRED" })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "LOGIN.VALIDATE.EMAIL.MIN" }))
      .max(50, "Maximum 50 symbols")
      .required(intl.formatMessage({ id: "LOGIN.VALIDATE.PASSWORD.REQUIRED" })),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        if (auth.errors) {
          setErrors(auth.errors);
          setSubmitting(false);
          setLoading(false);
          return;
        }

        saveAuth(auth.data);
        setCurrentUser(auth.data.user);
      } catch (error) {
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const imageSrc = toAbsoluteUrl("/media/logos/picaverse.svg");

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">
          {/* {intl.formatMessage({ id: "LOG-IN" })} */}
          <img src={imageSrc} alt="picaverse" className="w-25" />
          {/* <div style={{ fontSize: "20px", fontWeight: 700 }}>Picaverse</div> */}
        </h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "EMAIL" })}
        </label>
        <input
          autoFocus
          placeholder={intl.formatMessage({ id: "PLACEHOLDER.EMAIL" })}
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "PASSWORD" })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "PLACEHOLDER.PASSWORD" })}
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8"></div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "LOG-IN" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              기다리세요...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
